


























import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetBlacklist, dispatchUpdateBlacklist } from '@/store/main/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';
import { IBlacklist } from '@/interfaces';

@Component({
  components: {
    VTextFieldWithValidation,
  },
})
export default class EditBlacklist extends Vue {
  public isValid = true;
  public blacklist: IBlacklist = {} as IBlacklist;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    this.blacklist = await dispatchGetBlacklist(this.$store, Number(this.$router.currentRoute.params.id));
  }

  public async reset() {
    this.blacklist = await dispatchGetBlacklist(this.$store, Number(this.$router.currentRoute.params.id));
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const result = await dispatchUpdateBlacklist(this.$store, this.blacklist);
      if (result) {
        this.$router.push({ name: 'main-blacklist-all' });
      }
    }
  }
}
